import { useCallback, useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
interface AuthData {
  token: string | null
  origin?: string | null
}

let initialized = false

/**
 * The hook is responsible for loading the token from fragment and storing in local storage
 *
 * @returns The token
 */
export const useAuthToken = () => {
  useEffect(() => {
    if (!initialized) {
      initialized = true
    }
  }, [])

  const [results, setResults] = useState<AuthData>({
    token: null,
    origin,
  })

  useEffect(() => {
    Object.keys(results).forEach((key) => {
      const value = results[key as keyof AuthData]
      if (results.token && typeof value !== 'undefined' && value !== null) {
        window.appStorage.setItem(key, value.toString())
      }
    })
  }, [results])

  const getToken = useCallback(async (authCode: string | null) => {
    window.appStorage.removeItem('origin')

    const isFetching = {
      get: () => window.appStorage.getItem('token-fetching'),
      set: () => window.appStorage.setItem('token-fetching', 'true'),
      remove: () => window.appStorage.removeItem('token-fetching'),
    }

    const urlParams = new URLSearchParams(window.location.search)

    if (isFetching.get()) {
      // listen for token arrive if a prev fetch was in progress and set results:
      // (it makes sure that all useAuthToken hooks return the token)
      const onStorageChange = () => {
        if (!isFetching.get()) {
          const token = window.appStorage.getItem('token')
          const origin = window.appStorage.getItem('origin')
          if (!token) return

          setResults((res) => ({
            ...res,
            ...{ token, origin },
          }))
          window.removeEventListener('storage', onStorageChange)
        }
      }
      window.addEventListener('storage', onStorageChange, false)
    } else {
      isFetching.set()
      const response = await fetch(`${window.xundEnvironment.PP_ID_API_URL}/token`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: urlParams.get('clientId'),
          code: authCode,
          grant_type: 'authorization_code',
        }),
      })
      isFetching.remove()

      const responseJson = await response.json()

      if (responseJson) {
        const token = responseJson.access_token
        const origin = responseJson.origon

        setResults({ token, origin })

        const expirationDate = jwtDecode(token)?.exp

        if (expirationDate) {
          const now = new Date().getTime()
          setTimeout(() => {
            // response from embed.js with the new authCode:
            const onMessage = (event: MessageEvent) => {
              if (event.data.authCode) {
                getToken(event.data.authCode)
                window.removeEventListener('message', onMessage)
              }
            }
            window.addEventListener('message', onMessage, false)

            // ask embed.js for new authCode:
            window.parent.postMessage({ refreshToken: true }, '*')
          }, expirationDate * 1000 - now - 10 * 60 * 1000) // 50mins (runs 10mins before token expiration)
        }
      }
    }
  }, [])

  useEffect(() => {
    const tokenFromFragment = window.location.hash?.slice(1)

    const fetchToken = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.get('clientId') || !urlParams.get('authCode')) {
        throw new Error('No clientId or authCode provided')
      }

      await getToken(urlParams.get('authCode'))
    }

    const shouldUseCachedAuth = !!window.appStorage?.getItem('token')

    if (shouldUseCachedAuth) {
      setResults({
        token: window.appStorage?.getItem('token'),
        origin: window.appStorage?.getItem('origin'),
      })
    } else if (tokenFromFragment) {
      window.location.replace('#')
      if (typeof window.history.replaceState == 'function') {
        history.replaceState({}, '', window.location.href.slice(0, -1))
      }

      setResults({ token: tokenFromFragment })
    } else {
      fetchToken()
    }
  }, [getToken])

  return results
}
